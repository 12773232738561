<template>
    <div class="home">
        <el-form class="search_box" ref="form" :model="search" label-width="70px" size='medium' :inline="true">
            <el-form-item label="渠道编号" label-width="100px">
                <el-input-number v-model="search.id" :precision="0" :controls='false'></el-input-number>
            </el-form-item>
            <el-form-item label="渠道名字" label-width="100px">
                <el-input v-model="search.name"></el-input>
            </el-form-item>




            <!-- 全部 待发货  待收货  未支付  已完成  已取消 -->
            <el-form-item label-width="0">
                <el-button size='medium' type='primary' @click="serchFn">搜索</el-button>
                <el-button size='medium' type='primary' @click="emptys">重置</el-button>
            </el-form-item>
        </el-form>
        <div>
            <el-button size='medium' type='primary' @click="addChannel()">创建渠道</el-button>
        </div>
        <div class="posr">
            <el-table ref="multipleTableRef" v-loading="loadingShow" :data="tableData" style="width: 100%;margin-top: 20px;"
                :header-cell-style="{ background: '#F7F8FA', color: '#333333' }">
                <el-table-column prop="id" label="渠道编号" align="center">
                </el-table-column>
                <el-table-column prop="name" label="渠道名字" align="center">
                </el-table-column>
                <el-table-column prop="rolecount" label="渠道人数" align="center">
                </el-table-column>
                <el-table-column prop="createtime" label="创建时间" align="center">
                </el-table-column>
                <el-table-column align="center" fixed="right" width="160px" label="操作">
                    <template slot-scope="scope">
                        <el-button type="text" @click="deleteFn(scope.row)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <!-- <div class="footer-pagin">
                <el-pagination :current-page.sync="search.page" :page-sizes="pageSizes" :pager-count="5"
                    :page-size="search.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total"
                    @size-change="handleSizeChange" @current-change="handleCurrentChange" />
            </div> -->
        </div>

        <el-dialog title="创建渠道" :visible.sync="channelVisible" @close="closeDialog" width="40%">
            <div>
                <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="120px" class="demo-ruleForm">
                    <el-form-item label="渠道名字" prop="name">
                        <el-input v-model="ruleForm.name"></el-input>
                    </el-form-item>
                </el-form>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="channelVisible = false">取 消</el-button>
                <el-button type="primary" @click="submitForm('ruleForm')">确 定</el-button>
            </span>
        </el-dialog>


    </div>
</template>
  
<script>
import { GetChannelList, AddChannel, DelChannel } from '@/service/promotion/promotion.js'
import { Message, MessageBox} from 'element-ui'
export default {
    name: 'Home',
    components: {
        
    },
    data() {
        return {
            loadingShow: false,
            channelVisible: false,
            ruleForm: {},
            rules: {
                name: [
                    { required: true, message: '请填写渠道名字', trigger: 'blur' },
                ],
            },
            id: '',
            tableData: [],
            search: {
                // page: 1,
                // pageSize: 10,
            },
            pageSizes: [10, 20, 50, 100],
            total: 0
        }
    },
    created() {
        this.gettableList();
    },
    methods: {       
        deleteFn(row) {
            MessageBox.confirm('请确认是否删除, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.loadingShow = true
                DelChannel({
                    id: row.id,
                }).then(res => {
                    console.log(res)
                    this.loadingShow = false
                    Message.success(`删除成功`)
                    this.gettableList();
                }).catch(err => {
                    this.loadingShow = false
                    console.log(err)
                })
            }).catch(() => {
                Message.info('取消删除!')
            })
        },
        addChannel() {
            this.channelVisible = true
        },
        
        closeDialog() {
            this.ruleForm = {}
        },
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (!valid) return;
                this.loadingShow = true
                // userId: this.id,
                AddChannel({ name: this.ruleForm.name }).then(res => {
                    this.loadingShow = false;
                    console.log(res)
                    this.channelVisible = false
                    Message.success(`添加成功`)
                    this.gettableList()
                }).catch(err => {
                    console.log(err)
                    this.loadingShow = false;
                })

            });
        },
        serchFn() {
            this.gettableList();
        },
        emptys() {
            for (const key in this.search) {
                this.search[key] = undefined
            }
            // this.search.page = 1
            // this.search.pageSize = 10
            this.serchFn()
        },
        gettableList() {
            this.loadingShow = true
            const query = {
                ...this.search
            }

            GetChannelList(query).then(res => {
                this.loadingShow = false;
                console.log(res)
                this.tableData = res.data
                // this.total = res.data.count
            }).catch(err => {
                console.log(err)
                this.loadingShow = false;
            })
        },
        // 每页的条数
        handleSizeChange(val) {
            this.search.pageSize = val;
            this.gettableList();
        },
        // 分页
        handleCurrentChange(val) {
            this.search.page = val;
            this.gettableList();
        },
    }
}
</script>
<style lang="less" scoped>
.posr {
    position: relative;
}

.footer-pagin {
    margin: 20px;
    text-align: right;
}

.t-c {
    text-align: center;
}
.questions-box{
    margin-bottom: 15px;
    padding-right: 30px;
    position: relative;
    .el-icon-delete{
        position: absolute;
        right: -30px;
        font-size: 16px;
        top: 0;
        color: red;
        cursor: pointer;
    }
}
</style>
  
  